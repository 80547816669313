import React, { useState, useContext } from 'react';
import { AppContext } from '../../context/context';
import { Table, Button, Pagination, Select, Group, TextInput, Grid, Divider } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const DataTableInterlineales = ({ data, setId, conteocr, conteoh, conteov, conteocs }) => {

    const appcontext = useContext(AppContext);
    const columns = React.useMemo(() => {
        if (data.length === 0) return [];
        var headers = ["FECHA VUELO", "EJECUTIVO", "# GUIA", "QTY", "WEIGHT", "CM3", "TONS", "DESC", "SCH", "DIMS", "FORWARDER", "OPT"]
        //carriers
        headers.push("CARRIER ");
        for (let i = 2; i < conteocr; i++)
            headers.push("CARRIER " + i);

        headers.push("ORIGIN");
        //hubs
        headers.push("HUBS DEP");
        for (let i = 1; i < conteoh; i++)
            headers.push("HUBS " + i);

        headers.push("DEST");
        ///vuelos
        headers.push("VUELO ");
        for (let i = 2; i < conteov; i++)
            headers.push("VUELO " + i);

        //conexions
        headers.push("CNX DETAILS");
        for (let i = 2; i < conteocs; i++)
            headers.push("CNX DETAILS " + i);

        headers.push("REMARKS");
        headers.push("TIPO");
        return headers;
    }, [data]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [filter, setFilter] = useState('');

    const filteredData = data.filter(item =>
        Object.values(item).some(value =>
            value.toString().toLowerCase().includes(filter.toLowerCase())
        )
    );

    const totalRecords = filteredData.length;
    const totalPages = Math.ceil(filteredData.length / pageSize);
    const startIndex = (page - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalRecords);
    const paginatedData = filteredData.slice(startIndex, startIndex + pageSize);


    const handleEdit = (id) => {
        setId(id);
        appcontext.setStatusFNR(true);
    }

    const handleInfo = (id) => {
        setId(id);
        appcontext.setStatusFNI(true);
    }    
    return (
        <>
            <Group justify="space-between">
                <Select
                    label="Registros por pagina"
                    value={pageSize.toString()}
                    onChange={(value) => setPageSize(parseInt(value, 10))}
                    data={[5, 10, 20, 30, 50].map((size) => ({ value: size.toString(), label: size.toString() }))}
                />
                <TextInput
                    label="Buscar"
                    placeholder="Buscar..."
                    value={filter}
                    onChange={(event) => { setFilter(event.currentTarget.value); setPage(1) }}
                />
            </Group>
            <Grid.Col span={12}>
                <Divider size="sm" />
            </Grid.Col>

            <Table.ScrollContainer minWidth={500} type="native">
                <Table id="example" striped highlightOnHover withTableBorder>
                    <Table.Thead >
                        <Table.Tr>
                            <Table.Th style={{ backgroundColor: '#035cf7', color: '#F2F2F0' }}>
                                ACCIONES
                            </Table.Th>
                            {columns.map(column => (
                                <Table.Th key={column} style={{ backgroundColor: '#035cf7', color: '#F2F2F0' }}>
                                    {column}
                                </Table.Th>
                            ))}
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {                            
                            paginatedData.map((row, rowIndex) => (                                
                                <Table.Tr key={rowIndex}>
                                    <Table.Td>
                                        <Button.Group>
                                            <Button variant="outline" color='#FCC732' onClick={() => { handleEdit(row["id"]) }}><FontAwesomeIcon icon={faPen} /></Button>
                                            <Button variant="outline" color='#035CF7' onClick={() => { handleInfo(row["id"]) }}><FontAwesomeIcon icon={faCircleInfo} /></Button>
                                        </Button.Group>
                                    </Table.Td>
                                    {columns.map(column => {
                                        return (
                                            <Table.Td key={column}>
                                                {row[column]}
                                            </Table.Td>
                                        );
                                    }
                                    )}
                                </Table.Tr>
                            ))
                        }
                    </Table.Tbody>
                </Table>
            </Table.ScrollContainer>
            <Grid.Col span={12}>
                <Divider size="sm" />
            </Grid.Col>
            <Group justify="space-between">
                <Pagination
                    total={totalPages}
                    page={page}
                    onChange={setPage}
                    styles={{ control: { margin: '0 5px' } }}
                />
                <div style={{ marginTop: '10px' }}>
                    Showing {startIndex + 1} to {(endIndex + 1) > totalRecords ? totalRecords : endIndex + 1} of {totalRecords} entries
                </div>
            </Group>

        </>
    );
};

export default DataTableInterlineales;